import Vue from 'vue'
import App from './App.vue'

import axios from 'axios'
import router from './router'
import store from './store'

import hostOmni from "@/config/host";
require('./config/axiosconfig.js')
import VueDragTree from 'vue-drag-tree'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// Icones
import '@/assets/lib/icones/fontawesome/css/all.min.css'
import '@/assets/lib/icones/fontawesome/css/v4-shims.min.css'
// Cliente
import '@/assets/Template/CSS/Cliente.css'
import '@/assets/Template/CSS/login.css'
// Vue chart
import {VueCharts} from 'vue-chartjs'
// drag and drop 
import VueDraggable from 'vue-draggable'
// form validate
import Vuelidate from 'vuelidate'
import VueTree from '@ssthouse/vue-tree-chart'
import vueKanban from 'vue-kanban'

import { BIcon } from 'bootstrap-vue'
import moment from "moment";
import { isOnline } from "@/assets/context/network";

Vue.component('b-icon', BIcon)

Vue.prototype.$hostOmni = axios.defaults.baseURL

Vue.config.productionTip = false

// global
Vue.component('vue-tree', VueTree);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueCharts);
Vue.use(VueDraggable);
Vue.use(Vuelidate);
Vue.use(VueDragTree);
Vue.use(vueKanban);

// Defini��o de vari�veis globais observ�veis para que possam ser utilizadas nos watchers.
{
	// Define uma vari�vel global para conceder acesso nas inst�ncias Vue.
	Object.defineProperty(Vue.prototype, "$isOnline", {
		get() {
			return isOnline.value;
		}
	});
}

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app');

// SDWebSocket.js
let sdWebSocketScript = document.createElement("script");
sdWebSocketScript.setAttribute("src", `${hostOmni}/lib/sd-websocket/SDWebSocket.js?v=${moment().format("YYYYMMDDHH")}`);
document.head.appendChild(sdWebSocketScript);