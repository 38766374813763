import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

const LoginPage = () => import(/* webpackChunkName: "login-page" */ '@/views/LoginPage.vue')
const DashboardPage = () => import(/* webpackChunkName: "dashboard-page" */ '@/views/Dashboard.vue')

const AtendimentoPage = () => import(/*webpackChunckName: "atendimento-page"*/ "@/views/AtendimentoPage.vue");
const ArvoreDecisoesPage = () => import(/* webpackChunkName: "arvore-decisoes-page" */ '@/views/ArvoreDecisoesPage.vue')

const CadastrosBasicosPage = () => import(/* webpackChunkName: "cadastros-basicos-page" */ '@/views/CadastrosBasicosPage.vue')
const ConsultasPage = () => import(/* webpackChunkName: "consultas-page" */ '@/views/ConsultasPage.vue')
const CampanhasPage = () => import(/* webpackChunkName: "campanhas-page" */ '@/views/CampanhasPage.vue')
const LicencasPage = () => import(/* webpackChunkName: "licencas-page" */ '@/views/LicencasPage.vue')
const SupervisorPage = () => import(/* webpackChunkName: "supervisor-page" */ '@/views/SupervisorPage.vue')
const FaqPage = () => import(/* webpackChunkName: "faq-page" */ '@/views/FaqPage.vue')
const KanbanPage = () => import(/* webpackChunkName: "kanban-page" */ '@/views/KanbanPage.vue')
const SalaGruposPage = () => import(/* webpackChunkName: "sala-grupos-page" */ '@/views/SalaGruposPage.vue')
const ChatTestePage = () => import(/* webpackChunkName: "chat-teste-page" */ '@/views/ChatTestePage.vue')

const MonitoramentoPage = () => import(/* webpackChunkName: "monitoramento-page" */ '@/views/MonitoramentoPage.vue')

import routesCadastroBasicos from './cadastros-basicos.js'
import routesConsultas from './consultas.js'
import routesCampanhas from './campanhas.js'

Vue.use(VueRouter)

const routesMenu = [

    {
        path: '/login',
        name: 'LoginPage',
        component: LoginPage,
        children: [{
            path: 'recuperar-senha',
            default: LoginPage,
        }]
    },
    {
        path: '/',
        name: 'DashboardPage',
        component: DashboardPage
    },
    {
        path: '/atendimento',
        name: 'AtendimentoPage',
        component: AtendimentoPage
    },
    {
        path: '/arvore-decisoes/:id?',
        name: 'ArvoreDecisoesPage',
        component: ArvoreDecisoesPage
    },
    {
        path: '/cadastros-basicos',
        name: 'CadastrosBasicosPage',
        component: CadastrosBasicosPage
    },
    {
        path: '/consultas',
        name: 'ConsultasPage',
        component: ConsultasPage
    },
    {
        path: '/campanhas',
        name: 'CampanhasPage',
        component: CampanhasPage
    }, 
    {
        path: '/licencas',
        name: 'LicencasPage',
        component: LicencasPage,
        meta: { authorize: ['Master', 'Comercial', 'Gestor'] }
    },
    {
        path: '/supervisor',
        name: 'SupervisorPage',
        component: SupervisorPage,
        meta: { authorize: ['Master', 'Supervisor', 'Comercial', 'Gestor'] }
    },
    {
        path: '/faq',
        name: 'FaqPage',
        component: FaqPage,
        meta: { authorize: ['Master', 'Supervisor', 'Comercial', 'Gestor'] }
    },
    {
        path: '/kanban/:id?/:view?',
        name: 'KanbanPage',
        component: KanbanPage,
    },
    {
        path: '/sala-grupos',
        name: 'SalaGruposPage',
        component: SalaGruposPage
    },
    {
        path: '/Chat',
        name: 'ChatTestePage',
        component: ChatTestePage
    },
    {
        path: '/monitoramento',
        name: 'MonitoramentoPage',
        component: MonitoramentoPage
    },
]

const router = new VueRouter({
    mode: 'history',
    routes: [
        ...routesMenu,
        ...routesCadastroBasicos,
        ...routesConsultas,
        ...routesCampanhas
    ]
})

router.beforeEach(async (to, from, next) => {
    const authRequired = !to.path.startsWith('/login');
    const autenticado = await store.dispatch('authentication/verifica')

    const { authorize } = to.meta;
    const dadosUsuarioAutenticado = await store.getters['authentication/authDadosUsuario']
    const isUsuarioOnline = await store.getters['usuarios/isUsuarioOnline']

    if (!isUsuarioOnline && localStorage.getItem('login') == null) {
        window.localStorage.removeItem('login')
    }
    
    store.dispatch('setLoadingPage', true)

    if (!autenticado && authRequired) {
        return next('/login');
    }

    if (autenticado && !authRequired) {
        return next('/');
    }

    if (authorize) {
        if (!dadosUsuarioAutenticado) {
            return next('/login');
        }

        //Restricoes por perfil do usuario
        if (authorize.length && !authorize.includes(dadosUsuarioAutenticado.perfil)) {
            return next('/');
        }
    }

    return next();
})

router.afterEach(() => {
    store.dispatch('setLoadingPage', false)
})

export default router
