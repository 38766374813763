<template>
    <div class="menu-lateral-container app-scroll-custom">

        <ul class="menu-lateral-lista" :class="aberto? 'menu-lateral-aberto': ''">

            <li class="menu-lateral-item"
                v-for="(item, index) in itensMenu"
                v-bind:key="index"
                :class="indiceAtivo === index? 'item-ativo': ''"
                :title="item.tituloHover">
            
                <router-link :to="item.path" class="menu-lateral-link">
                    <i class="menu-lateral-icone-item" :class="item.classeIcone" ></i>
                    <span class="menu-lateral-texto-item">
                        {{item.texto}}
                    </span>
                </router-link>
            </li>

        </ul>

    </div>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'MenuLateral',
        components: {},
        props: {
            aberto: {
                type: Boolean,
                default: false,
            },
            indiceAtivo: {
                type: Number,
                default: 0,
            },
            itensMenu: {
                type: Array,
                default: () => []
            },
            modulosAtivos: {
                type: Array,
                default: () => []
            }
        },
        mounted() {
            this.buscaModulos()
        },
        methods: {
            ...mapActions({
                buscaModulos: 'modulos_integracoes/buscaModulos'
            }),
        }
    }
</script>

<style scoped>

.menu-lateral-container {
    background-color: #ccc;
    height: 100vh;
    padding-bottom: 35px;
}

.menu-lateral-lista {
    list-style-type: none;
    padding-left: 0px;
}

.menu-lateral-lista .item-ativo {
    color: #257b92 !important;
}

.menu-lateral-item {
    padding: 0px 2px;
    color: #4f4c4d;
    border-bottom: 1px solid rgb(187, 187, 187);
    font-size: large;
    cursor: pointer;
}
.menu-lateral-item:hover {
    background-color: rgba(187, 187, 187, 0.7);
    opacity: 0.7;
}

.menu-lateral-link {
    display: inline-block;
    color: inherit;
    text-decoration: none;
    height: 100%;
    width: 100%;
    padding: 16px 4px;
}

.menu-lateral-icone-item {
    width: 50px;
    text-align: center;
}

.menu-lateral-texto-item {
    display: none;
    margin-right: 12px;
}

.menu-lateral-aberto .menu-lateral-texto-item {
    display: inline;
}

</style>