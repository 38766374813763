<template>
    <div id="app" :class="menuLateralAberto? 'grid-aberto' : 'grid-fechado'">
      
        <div class="header" v-if="dadosUsuarioAutenticado">
            <menu-top :atendimentoDisponivel="usuario.Pause"
                      :usuarioWhatsWeb="usuario.UserWhatsweb"
                      @toggle="menuLateralAberto = !menuLateralAberto"
                      @alterarDisponibilidadeAtendimento="alterarDisponibilidadeAtendimento"
                      @redefinir="alterarSenha"
                      @alterarNome="alterarNome"
                      @logout="logOut" />
        </div>

        <div class="menu-lateral" v-if="dadosUsuarioAutenticado">
            <menu-lateral :aberto="menuLateralAberto"
                          :indiceAtivo="indiceMenu"
                          :itensMenu="itensMenuLateralAutorizados"
                          :modulosAtivos="getModulosAtivos" />
            Teste
        </div>

        <popup-alterar-senha ref="popupAlterarSenha" />
        <popup-alterar-nome ref="popupAlterarNome" />


        <div class="main app-scroll-custom">
            <b-overlay id="overlay-page-background"
                       :show="loadingPage"
                       :class="loadingPage ? 'h-100' : 'invisible'">
                <div class="h-100"></div>
            </b-overlay>

            <router-view v-if="!loadingPage" />
        </div>

        <b-overlay id="overlay-background"
                   :show="loading"
                   no-wrap
                   rounded="sm"
                   fixed />

    </div>
</template>

<script>
    import usuarioLogado from '@/assets/js/providers/usuario-logado';
    import { mapGetters, mapActions } from 'vuex'
    import MenuTop from '@/components/MenuTop.vue'
    import MenuLateral from '@/components/MenuLateral.vue'
   
    const PopupAlterarSenha = () => import(/* webpackChunkName: "popup-altera-senha" */ '@/components/popups/AlterarSenhaPopup.vue')
    const PopupAlterarNome = () => import(/* webpackChunkName: "popup-altera-nome" */ '@/components/popups/AlterarNomePopup.vue')
   
    export default {
        name: 'App',

        components: {
            MenuTop,
            MenuLateral,
            PopupAlterarSenha,
            PopupAlterarNome
        },

        data: function () {
            return {
                menuLateralAberto: false,
                indiceMenu: -1,
                itensMenuLateral: [
                    {
                        tituloHover: "Dashboard",
                        path: "/",
                        classeIcone: "	fas fa-home",
                        texto: "Dashboard",
                        perfisAutorizados: [],
                        atendenteSimples: true
                    },
                    {
                        tituloHover: "Atendimento",
                        path: "/atendimento",
                        classeIcone: "fas fa-headset",
                        texto: "Atendimento",
                        perfisAutorizados: [],
                        atendenteSimples: true
                    },
					{
						tituloHover: "Nova Árvore de Decisões",
						path: "/arvore-decisoes",
						classeIcone: "fas fa-project-diagram",
						texto: "Árvore de decisões",
                        perfisAutorizados: ['master', 'gestor'],
                        atendenteSimples: false
					},
                    {
                        tituloHover: "Cadastros Básicos",
                        path: "/cadastros-basicos",
                        classeIcone: "fas fa-cogs",
                        texto: "Cad. Básicos",
                        perfisAutorizados: ['supervisor','master', 'comercial', 'rh', 'gestor'],
                        atendenteSimples: false
                    },
                    {
                        tituloHover: "Consultas",
                        path: "/consultas",
                        classeIcone: "fas fa-search",
                        texto: "Consultas",
                        perfisAutorizados: ['master', 'supervisor', 'sdww', 'comercial', 'gestor', 'atendente'],
                        atendenteSimples: false
                    },
                    {
                        tituloHover: "Campanhas",
                        path: "/campanhas",
                        classeIcone: "fas fa-bullhorn",
                        texto: "Campanhas",
                        perfisAutorizados: ['master', 'supervisor', 'sdww', 'comercial', 'gestor'],
                        atendenteSimples: false
                    },
                    {
                        tituloHover: "Licenças",
                        path: "/licencas",
                        classeIcone: "fas fa-address-card",
                        texto: "Licenças",
                        perfisAutorizados: ['master'],
                        atendenteSimples: false
                    },
                    {

                        tituloHover: "Kanban",
                        path: "/kanban",
                        classeIcone: "fas fa-table",
						texto: "Kanban",
						perfisAutorizados: ['master', 'supervisor', 'sdww', 'comercial', 'gestor', 'atendente'],
                        atendenteSimples: false
                    },
                    {
                        tituloHover: "Duvidas",
                        path: "/faq",
                        classeIcone: "fas fa-question-circle",
                        texto: "Duvidas",
                        perfisAutorizados: ['master', 'supervisor', 'comercial', 'gestor'],
                        atendenteSimples: false
                    },
                    {
                        tituloHover: "Monitoramento",
                        path: "/monitoramento",
                        classeIcone: "fas fa-bug",
                        texto: "Monitoramento",
                        perfisAutorizados: ['master'],
                        atendenteSimples: false
                    }
                ],
                empresaDados: null
            }
        },

        computed: {
            ...mapGetters({
                dadosUsuarioAutenticado: 'authentication/authDadosUsuario',
                getModulosAtivos: 'authentication/getModulosAtivos',
                ModulosDisponiveis: 'modulos_integracoes/modulosItegracoes',
                loading: 'loadingGlobal',
                loadingPage: 'loadingPage'
            }),
            usuario: function () {
                return usuarioLogado
            },
            itensMenuLateralAutorizados() {
                if (this.dadosUsuarioAutenticado) {
                    if (this.dadosUsuarioAutenticado.ModulosEmpresa.includes(15) && this.dadosUsuarioAutenticado.perfil?.toLowerCase() == "atendente") {
                        return this.itensMenuLateral.filter(item => item.atendenteSimples);
                    }
                    //atendenteSimples
                    return this.itensMenuLateral.filter(item =>
                        item.perfisAutorizados.includes(this.dadosUsuarioAutenticado.perfil?.toLowerCase()) ||
                        item.perfisAutorizados.length === 0
                    );
                }
                return this.itensMenuLateral;
            },
            modulos() {
                this.ModulosDisponiveis.buscaModulos();
            },
           
        },

        watch: {
            '$route'(to) {
                this.iniciaMenuLateral(to)
            }
           
            
        },

        mounted() {
            
            this.iniciaMenuLateral(this.$route)
            this.initMostador({
                sucesso: (msg) => {
                    this.trataSucesso(msg)
                },
                erro: (msg) => {
                    this.trataErro(msg)
                },
                aviso: (msg) => {
                    this.trataAviso(msg)
                }
            })
           
        },

        created() {
            this.$store.dispatch('usuarios/buscaUsuarios');
            const mod = this.$store.getters['authentication/authDadosUsuario'];
        },

        methods: {
            ...mapActions({
                initMostador: 'mostradorDeMensagens/init',
                alterarDisponibilidadeAtendimento: 'usuarios/alterarDisponibilidadeAtendimento'
            }),
            
            logOut() {
                this.$store.dispatch('authentication/logout').then(() => { this.$router.push({ path: '/login' }); });
            },
            alterarSenha() {
                this.$refs.popupAlterarSenha.mostrarPopup();
            },
            alterarNome() {
                this.$refs.popupAlterarNome.mostrarPopup();
            },
            iniciaMenuLateral(to) {
                if (!to || !to.path) {
                    return
                }

                if (to.path && this.itensMenuLateral) {
                    this.itensMenuLateral.forEach((item, i) => {
                        if (item.path == to.path) {
                            this.indiceMenu = i;
                        }
                    });
                }
            },
            trataErro(msg) {
                this.$bvModal.msgBoxOk(msg, {
                    title: 'Algo deu errado',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Ok',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                    .then(valor => console.log(valor))
                    .catch(erro => console.log(erro))
            },
            trataSucesso(msg) {
                this.$bvModal.msgBoxOk(msg, {
                    title: 'Sucesso',
                    size: 'sm',
                    buttonSize: 'sm',                    
                    okVariant: 'success',
                    okTitle: 'Sim',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                    .then(valor => console.log(valor))
                    .catch(erro => console.log(erro))
            },
            
        },
    }
</script>

<style>
    .header {
        grid-area: header;
        background-color: #000
    }

    .menu-lateral {
        grid-area: menu-lateral;
    }

    .main {
        grid-area: main;
    }

    #app {
        padding: 0;
        margin: 0;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
        display: grid;
        grid-template-areas: 'header header header' 'menu-lateral main main';
        grid-template-columns: max-content auto auto max-content;
        height: 100vh;
        max-height: 100vh;
        overflow: hidden;
    }

    .app-scroll-custom {
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: grey lightgrey;
    }

        .app-scroll-custom::-webkit-scrollbar-track {
            background: lightgrey;
            border-radius: 0.15em;
        }

        .app-scroll-custom::-webkit-scrollbar-thumb {
            background: grey;
            border-radius: 0.15em;
        }

        .app-scroll-custom::-webkit-scrollbar {
            width: .5em;
            height: .5em;
        }


    .app-modal-custom-header {
        border: white !important;
        padding-bottom: 0 !important;
    }

    .app-modal-custom-body {
        padding-right: 50px !important;
        padding-left: 50px !important;
    }

    .app-modal-custom-footer {
        border: white !important;
    }

    .app-ck-html-container p {
        font-weight: normal;
        line-height: normal;
        margin-bottom: 0.3rem !important;
    }
</style>

